import React from "react";
import Card from "components/atoms/Card";
import { useTrans } from "system/translations/hooks";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import { useGetSettings } from "modules/settings/apiHooks";
import { ClickableTile, Column, Row } from "carbon-components-react";
import Loader from "components/atoms/Loader";
import { usePermissionAccess } from "modules/admin/helperHooks";

const Settings = () => {
  const history = useHistory();
  const { hasAccess } = usePermissionAccess()
  const dontShowSetting = ["Credentials"];
  const { _t } = useTrans();
  const { data = {}, isLoading } = useGetSettings();
  const linkHandler = (id: any) => {
    const link = generatePath(APP_ROUTES.settings.info, {
      settingId: id,
    });
    history.push(link, history.location);
  };
  if (!hasAccess('ROLE_VIEW_COUNTRIES')) {
    dontShowSetting.push('Countries')
  }
  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Card title={_t("settings")}>
      <Row>
        {Object.keys(data)?.map((item: any, idx) => {
          if (dontShowSetting.includes(item)) {
            return;
          }
          return (
            <Column lg={{ span: 8 }} md={{ span: 8 }} sm={{ span: 16 }}>
              <ClickableTile
                className={"bx--link-panel"}
                handleClick={() => linkHandler(Object.values(data)[idx])}
              >
                {item}
              </ClickableTile>
            </Column>
          );
        })}
      </Row>
    </Card>
  );
};

export default Settings;
