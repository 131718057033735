import { useMutation, useQuery, useQueryClient } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { useApiRequest } from "system/api/hooks";
import {
  LOGIN_TFA_URL,
  LOGIN_URL,
  RESET_PASSWORD_URL,
  API_GET_CURRENT_ADMIN,
  LOGOUT_URL,
  LOGIN_SSO_URL,
  LOGOUT_SSO_URL,
  API_GET_SSO_SETTINGS_URL,
} from "system/api/apiUrls";
import { APP_ROUTES } from "system/router/constants";
import {
  generateUrlQuery,
  checkAuth,
  setAuth,
  setSSOToken,
  removeAuthWithSSOToken,
  removeSSOToken,
  getSSOToken,
} from "system/helpers/helperFunctions";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useTrans } from "system/translations/hooks";
import { useToast } from "modules/toast/hooks";
import { AuthDataType, LoginDataType, SSOLoginDataType, SSOSettingsType } from "./types";
import { ErrorType } from "system/helpers/types";
import { CurrentUserType } from "modules/auth/types";

export const getCurrentAdmin = "getCurrentAdmin";
export const getSSOSettings = "getSSOSettings";

export const useLogin = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const queryClient = useQueryClient();
  const toastHandler = useToast();
  const { _t } = useTrans();
  return useMutation(
    (data: any): Promise<LoginDataType> =>
      apiRequest({
        url: LOGIN_URL,
        method: "post",
        data: data,
      }),
    {
      onSuccess: (responseData: LoginDataType) => {
        if (!responseData?.tfaenabled && responseData.confirmed) {
          setAuth();
          queryClient.refetchQueries(getCurrentAdmin);
          history.push(APP_ROUTES.transactions.index);
          if (!!getSSOToken()) {
            removeSSOToken()
          }
        } else if (
          typeof responseData?.confirmed === "boolean" &&
          !responseData.confirmed
        ) {
          toastHandler.addToast({
            caption: _t("user_not_confirmed"),
            kind: "error",
          });
        }
      },
      onError: (err: any) => {
        toastHandler.addToast({
          caption: err.errorData.message,
          kind: "error",
        });
      },
    }
  );
};

export const useLoginTfa = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const { apiErrors } = useApiErrors();
  const toastHandler = useToast();
  const { _t } = useTrans();

  return useMutation(
    (data: any): Promise<LoginDataType> =>
      apiRequest({
        url: LOGIN_TFA_URL,
        method: "post",
        data: data,
      }),
    {
      onSuccess: (responseData: LoginDataType) => {
        if (
          typeof responseData?.confirmed === "boolean" &&
          responseData?.confirmed
        ) {
          setAuth();
          history.push(APP_ROUTES.transactions.index);
        } else {
          toastHandler.addToast({
            caption: _t("user_not_confirmed"),
            kind: "error",
          });
        }
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useForgotPassword = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const { apiErrors } = useApiErrors();
  const toastHandler = useToast();
  const { _t } = useTrans();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: "/api/reset-password",
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        toastHandler.addToast({
          caption: _t("forgot_password_email_sent"),
          kind: "success",
        });
        history.push(APP_ROUTES.login);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useResetPassword = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: generateUrlQuery(RESET_PASSWORD_URL, { token: data.token }),
        method: "put",
        data: {
          password: data.password,
        },
      }),
    {
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useLogout = () => {
  const [apiRequest] = useApiRequest();
  const { apiErrors } = useApiErrors();
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    (data: any): Promise<AuthDataType> =>
      apiRequest({
        url: LOGOUT_URL,
        method: "get",
        data: {},
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(getCurrentAdmin);
        removeAuthWithSSOToken()
        history.push(APP_ROUTES.login);
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetCurrentAdmin = () => {
  const isAuthenticated = checkAuth();
  const [apiRequest] = useApiRequest();
  return useQuery<CurrentUserType>(
    [getCurrentAdmin],
    () =>
      apiRequest({
        url: generatePath(API_GET_CURRENT_ADMIN),
        method: "get",
      }),
    {
      enabled: !!isAuthenticated,
      staleTime: 5 * 60 * 1000,
    }
  );
};

export const useConvertAutorizationCodes = () => {
  const [apiRequest] = useApiRequest();
  const history = useHistory();
  const queryClient = useQueryClient();
  const toastHandler = useToast();
  const { _t } = useTrans();

  return useMutation(
    (data: any): Promise<any> =>
      apiRequest({
        url: LOGIN_SSO_URL,
        method: "post",
        data: data,
      }),
    {
      onSuccess: (responseData: SSOLoginDataType) => {
        if (responseData.external.confirmed) {
          setAuth();
          setSSOToken(responseData.internal.access_token)
          queryClient.refetchQueries(getCurrentAdmin);
          history.push(APP_ROUTES.transactions.index);
        } else if (
          typeof responseData?.external.confirmed === "boolean" &&
          !responseData.external.confirmed
        ) {
          toastHandler.addToast({
            caption: _t("user_not_confirmed"),
            kind: "error",
          });
        }
      },
      onError: (err: any) => {
        toastHandler.addToast({
          caption: err.errorData.message,
          kind: "error",
        });
      },
    }
  );
};

export const useSSOLogout = (ssoDomainUrl: string) => {
  const [ apiRequest ] = useApiRequest({ ssoDomainUrl });
  const { apiErrors } = useApiErrors();

  return useMutation(
    (): Promise<any> =>
      apiRequest({
        url: LOGOUT_SSO_URL,
        method: "post",
        data: {},
      }),
    {
      onSuccess: () => {
        removeAuthWithSSOToken();
        window.location.href = ssoDomainUrl;
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetSSOSettings = ({
  isDisabled
}:{
  isDisabled?: boolean
} = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery<SSOSettingsType>(
    [getSSOSettings],
    () =>
      apiRequest({
        url: API_GET_SSO_SETTINGS_URL,
        method: "get",
      }),
      {
        enabled: !isDisabled
      }
  );
};
