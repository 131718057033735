import Card from "components/atoms/Card";
import Loader from "components/atoms/Loader";
import SwaggerUI from "swagger-ui-react";
import 'swagger-ui-react/swagger-ui.css';
import { useTrans } from "system/translations/hooks";
import { useGetSwaggerDocumentationData } from "./apiHooks";

const SwaggerDocumentation = () => {
  const { _t } = useTrans()
  const { data, isLoading} = useGetSwaggerDocumentationData();


  if (isLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Card title={_t("api_documentation")}>
        <SwaggerUI spec={data}/>
    </Card>
);
};

export default SwaggerDocumentation;
