import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import {
  API_CREATE_MERCHANTS_FEE,
  API_GET_MERCHANTS_BY_GATEWAY,
  API_GET_MERCHANTS_FEE,
  API_GET_MERCHANTS_FEE_HISTORY,
  API_GET_MERCHANTS_FEE_HISTORY_BY_FIELD,
  API_GET_MERCHANTS_FEE_ITEM,
  API_GET_PAYMENT_GATEWAYS_FOR_FEES_BY_MERCHANT,
  API_UPDATE_ADVANCED_MERCHANTS_FEE,
  API_UPDATE_MERCHANTS_FEE,
} from "system/api/apiUrls";
import { generateUrlQuery } from "system/helpers/helperFunctions";
import { MerchantsFeesDataType, MerchantsFeesHistoryDataType, PaymentGatewaysDataType } from "./types";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";

const getMerchantsFeeKey = "getMerchantsFeeKey"; 
const getMerchantsFeeItemKey = "getMerchantsFeeItemKey";
const getGetMerchantsFeesHistory = "getGetMerchantsFeesHistory";
const getGetMerchantsFeesHistoryByField = "getGetMerchantsFeesHistoryByField";
const getMerchantsByProviderKey = "getMerchantsByProviderKey"; 
const getPaymentGatewaysForFeesByMerchantKey = "getPaymentGatewaysForFeesByMerchantKey"; 

export const useGetMerchantsFees = (filter: any = {}) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsFeeKey, filter],
    (): Promise<MerchantsFeesDataType[]> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_FEE, filter),
        method: "get",
      }),
  );
};
export const useGetMerchantsFeesItem = (id: number) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsFeeItemKey, id],
    (): Promise<MerchantsFeesDataType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_FEE_ITEM, { id: `${id}` }),
        method: "get",
      })
  );
};
export const useGetMerchantsFeesHistory = (
  merchantId: number,
  paymentGateway: string,
  fieldName: string
  ) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getGetMerchantsFeesHistory, merchantId, paymentGateway, fieldName],
    (): Promise<MerchantsFeesHistoryDataType> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_FEE_HISTORY, { merchantId: `${merchantId}`, paymentGateway, fieldName }),
        method: "get",
      })
  );
};

export const useGetMerchantsFeesHistoryByField = (
  merchantId: number,
  paymentGateway: string,
  fieldName: string
  ) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getGetMerchantsFeesHistoryByField, merchantId, paymentGateway, fieldName],
    (): Promise<any> =>
      apiRequest({
        url: generatePath(API_GET_MERCHANTS_FEE_HISTORY_BY_FIELD, { merchantId: `${merchantId}`, paymentGateway, fieldName }),
        method: "get",
      })
  );
};

export const useCreateMerchantsFees = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_MERCHANTS_FEE,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsFeeKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateMerchantsFees = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_UPDATE_MERCHANTS_FEE, {
          id: data.id,
        }),
        method: "put",
        data: data.data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsFeeKey);
        queryClient.invalidateQueries(getMerchantsFeeItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useUpdateAdvancedMerchantsFees = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_UPDATE_ADVANCED_MERCHANTS_FEE,
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getMerchantsFeeKey);
        queryClient.invalidateQueries(getMerchantsFeeItemKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetMerchantsByProvider = (groupType: any = {}) => {
  const [apiRequest] = useApiRequest();  
  return useQuery(
    [getMerchantsByProviderKey, groupType],
    (): Promise<any> =>
      apiRequest({
        url: generateUrlQuery(API_GET_MERCHANTS_BY_GATEWAY, groupType),
        method: "get",
      }),
  );
};

export const useGetPaymentGatewaysForFeesByMerchant = (merchantId?: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysForFeesByMerchantKey, merchantId],
    (): Promise<PaymentGatewaysDataType> =>
      apiRequest({
        url: generatePath(API_GET_PAYMENT_GATEWAYS_FOR_FEES_BY_MERCHANT, { merchantId: `${merchantId}`}),
        method: "get",
      }),
    { enabled: !!merchantId }
  );
};

