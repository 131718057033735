import { Information16, WarningAltFilled16 } from "../IconsCreateFactory"
import classNames from "classnames"

const InfoBlock = ({
  text,
  additionalText,
  withIcon = true,
  withWarning = false
}: {
  text: string,
  additionalText: string,
  withIcon: boolean,
  withWarning: boolean
}) => {
  return (
    <div className="info-block__information">
      {withIcon && (
        withWarning
          ? <WarningAltFilled16 className="info-block__information--warning-icon" />
          : <Information16 className="info-block__information--info-icon"/>
        )
      }
      <div className="info-block__text-container">
        <div className={classNames(!withIcon && "info-block__title-text")}>{text && text}</div>
        {additionalText && <div dangerouslySetInnerHTML={{ __html: additionalText }}></div>}
      </div>
    </div>
  )
}

export default InfoBlock