import axios, { Axios } from "axios";
import { ssoConfig } from "modules/auth/constants";
import backendUrl from "system/api/backendEnvUrl";
import { getSSOToken } from "system/helpers/helperFunctions";

const axiosClient: Axios = axios.create({
  baseURL: backendUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

axiosClient.interceptors.response.use(
  (response) => {
    const modifiedRes = { ...response };
    if (response.headers.hasOwnProperty("x-total-count")) {
      modifiedRes.data = {
        data: response.data,
        total: response.headers["x-total-count"],
      };
      //perform the manipulation here and change the response object
    }
    return modifiedRes;
  },
  (error) => {
    
    return Promise.reject(error);
  }
);


const createAxiosSSOClient = (ssoDomainUrl: string) => {
  const client = axios.create({
    baseURL: ssoDomainUrl,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  client.interceptors.request.use((config: any) => {
    const ssoToken = getSSOToken();
    if (ssoToken) {
      config.headers["Authorization"] = `Bearer ${ssoToken}`;
    }
    return config;
  });

  return client;
};



export { axiosClient, createAxiosSSOClient }
