import React from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig } from "modules/MerchantsFees/constantHooks";
import { useCreateMerchantsFees } from "../apiHooks";
import { createEditMerchantFeesValidation } from "../validation";

const Create: React.FC = () => {
  const { _t } = useTrans();
  const formConfig = useCreateEditConfig();
  const { mutate: onCreate } = useCreateMerchantsFees();
  const onSubmit = async (data: any) => {
    const normalizeData = {...data}
    normalizeData.merchantName = normalizeData.merchantId.name
    normalizeData.merchantId = normalizeData.merchantId.id

    if (normalizeData.settlementFeeType !== _t("fixed_fee")) {
      normalizeData.settlementFeeCurrency = null
    }
    delete normalizeData.settlementFeeType
    
    onCreate(normalizeData);
  };
  return (
    <Grid style={{ paddingLeft: "1rem" }} condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={_t("create_merchants_fees")}>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("create"),
                onSubmit: onSubmit,
                enableReinitialize: true,
                initialValues: { settlementFeeType:  _t("fixed_fee") },
                validationSchema: createEditMerchantFeesValidation(_t, {
                  isEdit: false,
                }),
              }}
            />
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Create;
