import Loader from "components/atoms/Loader";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { getLocalStorageItem } from "system/helpers/helperFunctions";
import { useConvertAutorizationCodes } from "../apiHooks";
import { ssoConfig } from "../constants";

const OAuthCallbackHandler = () => {
  const location = useLocation();
  const { mutate: onSSOLogin, isLoading } = useConvertAutorizationCodes();
  const { redirect_uri } = ssoConfig
  const getResponse = (requestData: any) => {
    onSSOLogin(requestData)
  }
  
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const codeVerifier = getLocalStorageItem("codeVerifier")
    const requestData = {
      redirect_uri: redirect_uri,
      code_verifier: codeVerifier,
      code: code
    }
    getResponse(requestData)
  }, []);

  return <Loader />;
}

export default OAuthCallbackHandler;