import { APP_ROUTES } from "system/router/constants";

export const includesBackgroundRoutes: string[] = [
  APP_ROUTES.login,
  APP_ROUTES.resetPassword,
];

export const ssoConfig = {
  redirect_uri: window.location.origin + APP_ROUTES.ssoLogin
}
