import defaultTranslations from "./tranlations";
import { UseTransResult, ParamsKey, TransKey } from "./types";
import { modifyTrans } from "./helper";
import { useContext } from "react";
import { TransContext } from "./InjectTransContext";
import { useParams } from "react-router-dom";

export const useSetLanguage = () => {
  const { lang }: any = useParams();
  localStorage.setItem("lang", lang);
  return [lang];
};

export const useTrans = (): UseTransResult => {
  const { messages = {} }: any = useContext(TransContext) || { messages: {} };  
  const dictionary = Object.keys(messages).length
    ? messages
    : defaultTranslations;

  const _t = (key: TransKey, params: ParamsKey = {}): string => {
    return dictionary[key]
      ? modifyTrans(dictionary[key], params)
      : `#${String(key)}#`;
  };
  return { _t };
};
