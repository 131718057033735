import { useCallback, useEffect, useMemo } from "react";
import { getSSOToken, removeAuthWithSSOToken } from "system/helpers/helperFunctions";
import { useGetSSOSettings, useSSOLogout } from "./apiHooks";
import { SSOWidgetSettingsType } from "./types";


export const useSSOWidget = (id: string) => {
  const token = getSSOToken();
  const { data } = useGetSSOSettings({
    isDisabled: !token
  });

  const { ssoWidgetUrl, ssoDomainUrl } = useMemo(() => {
    if (!data) return { ssoWidgetUrl: "", ssoDomainUrl: "" };
    return data;
  }, [data]);

  const { mutate: onLogout } = useSSOLogout(ssoDomainUrl);

  const onError = useCallback((err: any) => {
    if (err.status === 401) {
      removeAuthWithSSOToken()
    }
  }, []);

  useEffect(() => {
    if (!ssoWidgetUrl || !ssoDomainUrl || !token) return;

    const isAlreadyAdded = document.getElementById("sso-widget-script");
    if (isAlreadyAdded) return;

    const script = document.createElement("script");
    script.src = `${ssoWidgetUrl}index.js?timestamp=${Date.now()}`;
    script.async = true;
    script.id = "sso-widget-script";

    const config: SSOWidgetSettingsType = {
      rootSelector: `#${id}`,
      token: token,
      baseApiUrl: ssoDomainUrl,
      onError,
      onLogout,
    };

    script.onload = () => {
      if (typeof (window as any)?.initWidget === "function") {
        (window as any)?.initWidget(config);
      }
    };

    script.onerror = () => {
      console.error("SSO Widget: Failed to load the script");
    };

    document.head.appendChild(script);

  }, [ssoWidgetUrl, ssoDomainUrl, id, onError, onLogout, token])

};