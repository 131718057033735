import React, { useEffect, useMemo, useRef, useState } from "react";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { format, getMonth, getYear, parseISO } from "date-fns";
import { useOutsideClick } from "system/helpers/hooks"; // theme css file
import Input from "components/atoms/Input";
import config from "system/config";
import { CaretLeft24, CaretRight24, WarningAltFilled16 } from "components/atoms/IconsCreateFactory"
import classNames from "classnames";

const MonthPicker = ({
  value,
  onChange,
  dateName,
  dateFormat,
  labelText,
  placeholder,
  maxDate,
  isWarning = false,
  warningCustomText = '',
  ...rest
}: any) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const [clickOutSide] = useOutsideClick(ref);
  const [year, setYear] = useState(getYear(value ? new Date(value) : new Date()))

  const checkDate = (date: string) => {
    if (!date) {
      return new Date();
    }
    const parsedDate: any = parseISO(date);
    return parsedDate != "Invalid Date" ? parsedDate : new Date();
  };

  const data = useMemo(() => {    
    return checkDate(value)
  }, [value, dateName]);
  
  useEffect(() => {
    setShow(false);
  }, [clickOutSide]);
  const getYearByDate = (date: string) => getYear(new Date(date))
  const getMonthByDate = (date: string) => getMonth(new Date(date))
  
  return (
    <div className={"date-range"} ref={ref}>
      <div onClick={() => !clickOutSide && setShow(!show)}>
        <Input
          value={value ? `${format(data, dateFormat)}` : ''}
          onChange={() => { }}
          labelText={labelText !== "Date range" ? labelText : ''}
          placeholder={placeholder}
          isCalendar={!!placeholder}
          {...rest}
        />
        {isWarning && (
          <div className="date-range__warning">
            <WarningAltFilled16 className="info-block__information--warning-icon" />
            <div className="date-range__warning-text">{warningCustomText}</div>
          </div>
        )}
      </div>
        {show && (
          <div className="month-picker__container">
            <div className="month-picker__years">
              <CaretLeft24 
                className={
                  classNames("month-picker__carret",
                    {'month-picker__carret--disabled': (getYear(new Date()) - year) > 10}
                  )
                } 
                onClick={() => !((getYear(new Date()) - year) > 10) && setYear(year - 1)}
              />
              {year}
              <CaretRight24 
                className={
                  classNames("month-picker__carret",
                    {'month-picker__carret--disabled': (year - getYear(new Date())) > 10}
                  )
                } 
                onClick={() => !((year - getYear(new Date())) > 10) && setYear(year + 1)}
              />
              </div>
            <div className="month-picker__month">
              {config.monthNames.map((month, idx) => {
                const maxYear = getYearByDate(maxDate)
                const maxMonth = getMonthByDate(maxDate)

                const isDisabled = !!maxDate && (((maxMonth < idx) && maxYear <= year) || maxYear < year)
                const isSelected = getYearByDate(value) === year && getMonthByDate(value) === idx
                
                return (
                  <div className={classNames("month-picker__item", 
                      { "month-picker__item--selected" : isSelected },
                      { "month-picker__item--disabled" : isDisabled},
                    )}
                    onClick={() => onChange(format(new Date(`${year}-${idx + 1}-01`), dateFormat))}
                  >
                    {month.slice(0,3)}
                  </div>
                )
              })}
            </div>
          </div>
        )
        }
          
    </div>
  );
};

export default MonthPicker;
