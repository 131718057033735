import { isEmpty } from "lodash";
import { generatePath } from "react-router-dom";
import { ArrayOptionsMapRes } from "./types";

export const getNestedValue = (obj: any = {}, path: any, divider = ".") => {
  let properties = Array.isArray(path) ? path : path.split(divider);
  return properties.reduce((p: any, c: any) => p && p[c], obj);
};
export const getValueByKey = (data: any = {}, keys: string[] = []) => {
  let resData: any = {};
  keys.forEach((key) => {
    resData[key] = data?.[key] || undefined;
  });
  return resData;
};

export const checkAuth = () =>
  localStorage.getItem("isAuthenticated") === "true";
export const setAuth = () =>
  localStorage.setItem("isAuthenticated", "true");
export const removeAuth = () =>
  localStorage.removeItem("isAuthenticated");

export const getSSOToken = () =>
  localStorage.getItem("accessToken");
export const setSSOToken = (value: string) =>
  localStorage.setItem("accessToken", value);
export const removeSSOToken = () =>
  localStorage.removeItem("accessToken");

export const removeAuthWithSSOToken = () => {
  removeAuth(); 
  removeSSOToken();
}

export const getLocalStorageItem = (item: string) =>
  JSON.parse(<string>localStorage.getItem(item));
export const setLocalStorageItem = (itemName: string, val: any) =>
  localStorage.setItem(itemName, JSON.stringify(val));
export const removeLocalStorageItem = (item: string) =>
  localStorage.removeItem(item);

export const arrayOptionsMap = (
  data: any[],
  { labelKey, valueKey }: { labelKey: string; valueKey: string }
): ArrayOptionsMapRes => {
  return data?.map((item, idx) => ({
    label: typeof item !== "object" ? item : item[labelKey],
    value: typeof item !== "object" ? item : item[valueKey],
  }));
};

export const generateUrlQuery = (
  url: string,
  params: { [key: string]: any } = {}
) => {
  const preparedUrl = new URL(url, window.location.href);
  for (let key in params) {
    !!params[key] && params[key] !== '*' && preparedUrl.searchParams.append(key, params[key]);
  }
  //decodeURIComponent(preparedUrl.search)
  return preparedUrl.pathname + preparedUrl.search;
};

export const checkObjectType = (val: any) =>
  typeof val === "object" &&
  !Array.isArray(val) &&
  Object.prototype.toString.call(val) !== "[object File]";

export const checkStringType = (val: any) => typeof val === "string";
export const checkNumberType = (val: any) => typeof val === "number";
export const checkBooleanType = (val: any) => typeof val === "boolean";
export const checkArrayType = (val: any) => Array.isArray(val);

export const parseNestedData = (values: any) => {
  const resultData: any = {};
  let startKey = "";
  var parseQ = (values: any, parsDataKey: any) => {
    for (let key in values) {
      let resKey = (["", "searchWithFieldSelect"].includes(parsDataKey)) 
        ? key 
        : parsDataKey + `[${key}]`;
      if (checkObjectType(values[key])) {
        parseQ(values[key], resKey);
      } else {
        resultData[resKey] = values[key];
      }
    }
  };

  parseQ(values, startKey);
  return resultData;
};

const checkIfFiltersEmpty = (filters: any) => {
  if(
    !Object.values(filters).filter((filterItem: any) => 
      filterItem && filterItem.length).length
  ){ 
    return true
  }
  return false
}

export const filterArrayByValues = (data = [], filters: any) => {
  const flatFilters = parseNestedData(filters);
  if(checkIfFiltersEmpty(flatFilters)){ 
    return data
  }
  const filteredData = data?.filter((item: any) => {
    const flatDataItem = parseNestedData(item);
    let res = true;
    for (let key in flatFilters) {
      let prevRes: boolean = res
      if (
        flatDataItem.hasOwnProperty(key) &&
        checkStringType(flatDataItem[key]) &&
        checkStringType(flatFilters[key]) &&
        flatFilters[key].length
      ) {
        const val = flatDataItem[key].toLowerCase();
        const searchVal = flatFilters[key].toLowerCase();
        res = prevRes && val.includes(searchVal);
      } else if (
        flatDataItem.hasOwnProperty(key) &&
        Array.isArray(flatDataItem[key]) &&
        Array.isArray(flatFilters[key]) &&
        flatFilters[key].length
      ) {
        let containInArray = false;
        flatFilters[key].forEach((item: any) => {
          if (flatDataItem[key].includes(item)) {
            containInArray = true;
          }
        });
        res = prevRes && containInArray;
      } else if (
        flatDataItem.hasOwnProperty(key) &&
        Array.isArray(flatFilters[key]) &&
        flatFilters[key].length &&
        !checkArrayType(flatDataItem[key]) &&
        !checkObjectType(flatDataItem[key])
      ) {
        res = prevRes && flatFilters[key].includes(flatDataItem[key]);
      } else if (!!flatFilters[key] && !flatDataItem.hasOwnProperty(key) && flatFilters[key].length) {
        res = false; 
      }
    }
    return res;
  });  
  return filteredData;
};

export const make4SpacesInText = (text: string) =>
  text?.replace(/(.{4})/g, "$1 ").trim();

export const getUrl = (path: string, id: number) =>{
  return generatePath(path, {id})
}

export const getPreparedData = (data: any, key: string, currencyData: any = {} ) => {
  if (!isEmpty(currencyData)) {
    return data[key] 
      ? data[key] + ' ' + currencyData?.currency 
      : data[key] === 0
        ? data[key] + ' ' + currencyData?.currency
        : '-'
  }

  return data[key] 
    ? data[key] + ' ' + data?.currency 
    : data[key] === 0
      ? data[key] + ' ' + data?.currency
      : '-'

}

export const getValues = (values: object, specialKeys: string[]): object => Object.entries(values).reduce(
  (a, [key, value]) => specialKeys.includes(key) ? { ...a, [key]: value } : { ...a }, {})

export const getValueByPath = (obj: any, path: string) => {
    const keys = path.split(/\.|\[/).filter(Boolean);
    return keys.reduce((acc, key) => {
      return acc?.[key];
    }, obj);
  }

export const findPathToObject = (data: any, target: any, path = ""): string | null => {
  if (data === target) {
    return path;
  }

  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const result = findPathToObject(data[i], target, `${path}[${i}]`);
      if (result) return result;
    }
  } else if (typeof data === "object" && data !== null) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const currentPath = path ? `${path}.${key}` : key;
        const result = findPathToObject(data[key], target, currentPath);
        if (result) return result;
      }
    }
  }

  return null;
}


function generateRandomString(length: number) {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.~';
  let randomString = '';
  
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array); 

  for (let i = 0; i < length; i++) {
    randomString += characters[array[i] % characters.length];
  }
  
  return randomString;
}

export function generatePKCE() {
  return new Promise((resolve, reject) => {
    const codeVerifier = generateRandomString(128); 
    
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    
    window.crypto.subtle.digest('SHA-256', data).then(function(hashBuffer) {
      
      let hashArray = Array.from(new Uint8Array(hashBuffer));
      let base64String = btoa(String.fromCharCode(...hashArray)); 
      
      // Remove padding and make the Base64 string URL-safe
      const codeChallenge = base64String
        .replace(/=+$/, '') // Remove padding
        .replace(/\+/g, '-') // Replace '+' with '-'
        .replace(/\//g, '_'); // Replace '/' with '_'
      
      resolve({
        codeVerifier: codeVerifier,
        codeChallenge: codeChallenge
      });
    }).catch(reject);
  });
}
