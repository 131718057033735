import React, { useEffect } from "react";
import { generatePKCE, generateUrlQuery, setLocalStorageItem } from "system/helpers/helperFunctions";
import { ssoConfig } from "../constants";
import Loader from "components/atoms/Loader";
import { useGetSSOSettings } from "../apiHooks";

const LoginSSO: React.FC = () => {
  const { data } = useGetSSOSettings();
  const { redirect_uri } = ssoConfig

  useEffect(() => {
    if (data) {
      const {
        ssoDomainUrl,
        client_id,
        response_type,
        code_challenge_method
      } = data

      generatePKCE().then(({ codeVerifier, codeChallenge }: any) => {
        setLocalStorageItem("codeVerifier", codeVerifier)
        window.location.href = ssoDomainUrl + generateUrlQuery("/oauth/authorize", {
          redirect_uri,
          client_id,
          response_type,
          code_challenge_method,
          code_challenge: codeChallenge
        })
      }).catch((error) => {
        console.error('Error generating PKCE:', error);
      });
    }
  }, [data])
  

  return <Loader /> ;
};

export default LoginSSO;
